import React, { useContext } from 'react'
import { graphql, Link } from 'gatsby'
import SEO from '../components/seo'
import Img from 'gatsby-image'
import styled from 'styled-components'
import _ from 'lodash'

const IntroSection = styled.div`
  margin: 2em 0;
`

const PageContent = styled.div`
  p {
    margin: 1em 0;
  }
`

const Pagination = styled.ul`
  list-style: none;
  margin-top: 2em;
  border-top: 1px solid;
  padding: 1em 0;

  span {
    display: block;
  }
`

const Title = styled.span`
  font-size: 1.2rem;
  font-weight: bold;
`

const ArticlePage = ({ data, pageContext }) => {
  const collection = data.shopifyArticle
  const { previous, next } = pageContext

  return (
    <>
      <SEO title={collection.title} />
      <div>
        <p>
          <Link to="/"> Home </Link> <Link to="/blog"> / Blog </Link> /{' '}
          {collection.title}
        </p>
        <Img
          fluid={collection.image.localFile.childImageSharp.fluid}
          key={collection.id}
          alt={collection.title}
        />

        <IntroSection>
          <h1>{collection.title}</h1>
        </IntroSection>

        <PageContent
          dangerouslySetInnerHTML={{ __html: collection.contentHtml }}
        />

        <Pagination>
          <li>
            {next && (
              <Link to={`/blog/${_.kebabCase(next.title)}`} rel="next">
                {next.title}
                <span>← Previous</span>
              </Link>
            )}
          </li>

          <li>
            {previous && (
              <Link to={`/blog/${_.kebabCase(previous.title)}`} rel="prev">
                {previous.title}
                <span>Next →</span>
              </Link>
            )}
          </li>
        </Pagination>
      </div>
    </>
  )
}

export const query = graphql`
  query($id: String!) {
    shopifyArticle(id: { eq: $id }) {
      id
      title
      contentHtml
      image {
        localFile {
          childImageSharp {
            fluid(maxWidth: 910) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default ArticlePage
